export default class {
  constructor() {}

  /**
   * 写cookie
   * @param {*} token
   * @param {*} timer
   */
  setAccessTokenCookie(token, timer) {
    const d = new Date();
    d.setTime(
      timer
        ? d.getTime() - d.getTime()
        : d.getTime() + 30 * 24 * 1 * 60 * 60 * 1000
    ); // 设置Cookie的生命周期，这里设置为1小时
    const expires = "expires=" + d.toUTCString();
    document.cookie = "ACCESSTOKEN=" + token + ";" + expires + ";path=/"; // 将令牌添加到Cookie中
  }
  /**
   * 清除html标签
   * @param {*} htmlString
   * @returns
   */
  clearHtml(htmlString, allowedTags = []) {
    const tagsToClear = allowedTags.join("\b|");
    const regex = new RegExp(`<(?!${tagsToClear})[^>]*>`, "gi");
    return htmlString.replace(regex, "");
  }
}
