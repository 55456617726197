<template>
  <div class="page">
    <TitleBar></TitleBar>
    <router-view></router-view>
    <FooterBar></FooterBar>
  </div>
</template>

<script>
import TitleBar from "@/components/title-bar.vue";
import FooterBar from "@/components/footer-bar.vue";
import { mapMutations } from "vuex";
export default {
  name: "App",
  components: {
    TitleBar,
    FooterBar,
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
    getUserInfo() {
      const token = this.getAccessToken();
      if (token) {
        const args = {
          token,
        };
        this.api
          .post("/user/findByToken", args)
          .then((res) => {
            console.log(res);
            this.setUserInfo(res);
          })
          .catch((e) => {
            console.log("接口报错了", e);
          });
      }
    },
    getAccessToken() {
      const name = "ACCESSTOKEN=";
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.indexOf(name) === 0) {
          return cookie.substring(name.length, cookie.length);
        }
      }

      return "";
    },
  },
  mounted() {
    this.getUserInfo();
  },
};
</script>
