/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';//引入插件并使用插件
Vue.use(Vuex); //使用插件后就可以在vm,vc里使用store配置项

const actions = {};

//准备mutations,用于操作数据(state)
const mutations = {
  setUserInfo: (state, payload) => {
    state.userInfo = payload;
  }
};

//准备state,用于存储数据
const state = {
  userInfo: {}
};

const getters = {
  getUserInfo: (state) => {
    return state.userInfo;
  }
}

//创建store
const store = new Vuex.Store({
  actions: actions,
  mutations, //简写
  state, //简写
  getters
});

//导出store
export default store;
