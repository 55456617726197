<template>
  <div class="page-header">
    <div class="page-header-box">
      <img
        class="page-header-logo"
        src="http://59.110.162.130:8088/file/image/2023/11/30/logo.png"
        @click="toHome()"
      />
      <div class="page-header-more">
        <div class="page-header-search">
          <el-input
            size="small"
            placeholder="请输入搜索内容"
            :maxlength="50"
            v-model="searchKey"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search()"
            ></el-button>
          </el-input>
        </div>
        <div v-if="getUserInfo.username">
          <el-dropdown trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              你好，{{ getUserInfo.username }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="createArticle">
                发表文章
              </el-dropdown-item>
              <el-dropdown-item command="toAdmin" divided>
                管理后台
              </el-dropdown-item>
              <el-dropdown-item command="logout" divided>
                退出登录
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-else>
          <el-button size="small" icon="el-icon-user-solid" @click="toLogin()">
            访客登录
          </el-button>
          <el-button size="small" icon="el-icon-edit" @click="toRegister()">
            访客注册
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      searchKey: "",
    };
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
    toHome() {
      if (this.$route.path !== "/home") {
        this.$router.push({
          path: "/home",
        });
      } else {
        window.location.reload();
      }
    },
    toBack() {
      this.$router.go(-1);
    },
    toLogin() {
      this.$router.push({
        path: "/login",
      });
    },
    toRegister() {
      this.$router.push({
        path: "/register",
      });
    },
    search() {
      if (this.searchKey) {
        this.$message({
          message: `你想搜索${this.searchKey}，但是没有很遗憾搜索到内容`,
        });
      } else {
        this.$message({ message: `请输入您想搜索的内容` });
      }
    },
    handleCommand(command) {
      const commands = {
        logout: () => {
          this.$confirm("确认退出？")
            .then(() => {
              this.setUserInfo({});
              this.util.setAccessTokenCookie("", -1);
            })
            .catch(() => {});
        },
        createArticle: () => {
          this.$router.push("/createArticle");
        },
        toAdmin: () => {
          this.$router.push({
            path: '/admin'
          })
        }
      };
      commands[command]();
    },
  },
};
</script>
