import axios from 'axios'
import {Message, Loading} from 'element-ui'
import Util from '@/utils/util'

const util = new Util();

let loading = null;

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL, // 设置默认的基础URL
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做一些处理
  // 在这里可以修改请求头、添加token等
  loading = Loading.service({
    lock: false,
    background: 'rgba(0, 0, 0, 0.05)'
  });
  return config;
}, function (error) {
  // 对请求错误做一些处理
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(
  response => {
    setTimeout(() => {
      loading.close();
    }, 300);
    // 对响应数据进行处理
    // 可以根据需要进行相应的操作
    if(response && response.data) {
      // eslint-disable-next-line
      // debugger
      if(response.data.code === 200) {
        return response.data.data;
      } else if(response.data.code === 500) {
        Message({
          type: 'error',
          message: response.data.msg || '接口错误'
        });
        return Promise.reject(response.data);
      } if(response.data.code === 0) {
        util.setAccessTokenCookie('', 0);
        return Promise.reject(response.data);
      } else {
        return Promise.reject(response.data);
      }
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    Message({
      type: 'error',
      message: '接口错误：' + error.message
    });
    // 对响应错误进行处理
    // 可以根据需要进行相应的操作
    return Promise.reject(error);
  }
);


export default instance