<template>
  <div class="page-footer">
    <div class="page-footer-box">
      <div>
        <div>
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11011502037584" rel="noreferrer" target="_blank">京公网安备11011502037584</a>
        </div>
        <div>
          <a href="https://beian.miit.gov.cn" target="_blank">京ICP备2023036672号</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchKey: ''
    }
  },
  methods: {
    toHome() {
      if(this.$route.path !== '/home') {
        this.$router.push({
          path: '/home'
        })
      } else {
        window.location.reload();
      }
    },
    toBack() {
      this.$router.go(-1);
    },
    search() {
      if(this.searchKey) {
        this.$message({message: `你想搜索${this.searchKey}，但是没有很遗憾搜索到内容`});
      } else {
        this.$message({message: `请输入您想搜索的内容`});
      }
    }
  },
};
</script>
