import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import router from './router'


import store from './store/index.js';

import '@/assets/common.scss'
import api from '@/api'

Vue.config.productionTip = false

Vue.prototype.api = api;

import '@wangeditor/editor/dist/css/style.css'

import Util from '@/utils/util.js'
Vue.prototype.util = new Util();

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
